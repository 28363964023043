.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*{
  margin: 0;
  font-family: Poppins, Helvetica, "sans-serif";
  /* font-size: 1rem; */
  /* font-weight: 400; */
  line-height: 1.4;
  /* color: #3F4254; */
  text-align: center;
  }
.dashboardIcon{
  color:white;
  font-size:1.4em;
  margin-bottom:0.3em
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.main-wrapper {
  background-color: #ebf1f6;
}
.card-body {
  text-align: initial;
}
.card-custom {
  text-align: initial;
}
.container-fluid{
    position: relative;
    height: 100%;
    max-width: 1500px!important;
    margin: 0 auto;
}
.fw-semibold {
  font-style: revert;
  font-size: inherit;
}

.mb-0 {
  text-align: initial;
}
.react-datepicker-popper {
  z-index: 1;
}
.py-2 {
  text-align: initial;
}
.py-3 {
  text-align: initial;
}

.btn-outline-danger {
  --bs-btn-color: rgba(255, 0, 0, 0.705);
  --bs-btn-border-color: red;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: red;
  --bs-btn-hover-border-color: rgba(255, 0, 0, 0.897);
  --bs-btn-focus-shadow-rgb: 250, 137, 107;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: red;
  --bs-btn-active-border-color: red;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: red;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: red;
  --bs-gradient: none;
}

.text-danger {
  font-weight: bold;
  color: rgba(255, 0, 0, 0.842) !important;
}
 .app-header .navbar {
  min-height: 50px!important;
  height: 40px !important;
}
.sidebar-nav {
  overflow-y: unset;
  padding: 1px !important;
}
.sidebar-link {
  FONT-WEIGHT: 400;
  color: #007AB1;
  display: flex;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  line-height: 25px;
  position: inherit;
  margin: 0 0 2px;
  padding: 10px;
  border-radius: 7px;
  gap: 15px;
  text-decoration: none;
  font-weight: 400;
}
.css-1ygcj2i-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 545 !important;
  font-size: 0.80rem;
  line-height: 1.4rem !important;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  padding: 7px !important;
  color: rgba(0, 0, 0, 0.87);
}
.nav-small-cap {
  margin-top: 24px;
  color: #2a3547;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 10px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: left;
}

.bg-light-info { 
  background: linear-gradient(45deg, #3c9526, transparent);
}   
.bg-light-success { 
  background: linear-gradient(45deg, #007ab1, transparent);
}
.bg-light-warning {
  background: linear-gradient(45deg, #fea1a1, transparent);
}
.bg-light-danger { 
  background: linear-gradient(45deg, #917dfc, transparent);
}

.left-sidebar {
  width: 261px;
  height: 100px!important;
  position: fixed;
  padding-top: calc(50px + 7px) !important;
}

.scroll-sidebar {
  /* width: 270px; */
  background-color: #fff;
  position: absolute;
  transition: .2s ease-in;
  height: 90%;
  z-index: 11;
  border-right: 1px solid #e5eaef;
  padding: 0 24px!important;
  position: fixed; 
}
.MuiTablePagination-selectLabel { 
  margin-top: auto!important; 
}
.MuiTablePagination-displayedRows {
  margin-top: auto!important; 
}
.card-footer {
  text-align: end;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #007AB1!important;
}
.fa-plus-square{
  color: #007AB1!important;
}
.fa-minus-square{
  color: #007AB1!important;
}
.readonly{
  background: aliceblue!important;
}
.css-hyum1k-MuiToolbar-root {
  min-height: 40px !important;
}
.css-15wwp11-MuiTableHead-root {
  display: table-header-group;
  position: sticky!important;
}

#main-wrapper[data-layout=horizontal] .app-header .navbar .navbar-nav .nav-item .nav-link {
  height: 70px;
  line-height: 0px!important;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: inherit!important;
 
}
/* border-width: thin!important; patli line border All Table */ 
/* 
.fa-eercast:before {
  content: "\f2da";
}
.fa-eercast{
  font: normal normal normal 14px/1 FontAwesome;
  font-size: initial;
  color: #fff!important;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }  */

h3 {
  font-size: 2em;
  color: #000!important;
}

.swal2-warning {
  border-color: #ff4b03!important;
  color: #ff4b03!important;
}
hr {
  margin: 0.2rem 0!important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
  background-color: rgb(0 0 0 / 18%)!important;
}

.rounded-circle {
  border-radius: 50%!important;
  margin: auto;
}

.navbar-nav .dropdown-menu.content-dd {
  width: 300px;
}

.navbar-nav .dropdown-menu.content-dd {
  width: 300px;
}

 .body-wrapper>.container-fluid,#main-wrapper[data-layout=horizontal] .body-wrapper>.container-lg,#main-wrapper[data-layout=horizontal] .body-wrapper>.container-md,#main-wrapper[data-layout=horizontal] .body-wrapper>.container-sm,#main-wrapper[data-layout=horizontal] .body-wrapper>.container-xl,#main-wrapper[data-layout=horizontal] .body-wrapper>.container-xxl,#main-wrapper[data-layout=horizontal] .navbar,#main-wrapper[data-layout=horizontal] .scroll-sidebar {
  position: relative;
  max-width: 1500px;
  margin: 0 auto
}

.w3layouts-main{
  background: linear-gradient(45deg, #007ab1, transparent)
}

#main-wrapper[data-layout=horizontal] .sidebar-nav #sidebarnav>.sidebar-item:last-child>.first-level ul.second-level{
  right: -225px;
  top: 60px;
  left: auto;
}